import { useQuery } from 'react-query'
import { ApiClient, AgentData } from '../../clients'
import { useConfig } from '../config'

type UseGetAgentProps = {
  id: string | undefined
}

type UseGetAgentHook = {
  isGetAgentLoading: boolean
  isGetAgentError: boolean
  getAgentError: Error | null
  agentData: AgentData | undefined
}

/**
 * Hook to get any profile data given an id/username
 */
export function useGetAgent({ id }: UseGetAgentProps): UseGetAgentHook {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, error, isError } = useQuery<AgentData, Error>(
    id ?? '',
    async () => {
      if (id) {
        return await apiClient.getAgentData(id)
      }
      throw new Error(`id not specified`)
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: id !== undefined,
    }
  )

  return {
    isGetAgentLoading: isLoading,
    isGetAgentError: isError,
    getAgentError: error,
    agentData: data,
  }
}
