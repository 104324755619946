import { Flex, useToasts } from '@applyboard/crystal-ui'
import { Navigate, useParams } from 'react-router-dom'
import { NavBars } from '../../components/NavBars'
import { PageHeading } from '../../components/PageHeading'
import { LoadingPage } from '../LoadingPage'
import { useGetAgent } from 'src/hooks/agents/useGetAgent'
import { AgentForm } from 'src/components/AgentForm'
import { useProfile } from 'src/hooks'
import { UserType } from 'src/clients'

export function EditAgentPage(): JSX.Element {
  const { id } = useParams()
  const toasts = useToasts()
  const { agentData, isGetAgentLoading } = useGetAgent({ id });
  const {
    profileData: currentSessionUser,
    isGetProfileLoading: isGetCurrentSessionUserLoading,
  } = useProfile()

  const currentSessionUserIsResolved =
    !isGetCurrentSessionUserLoading && currentSessionUser

  if (isGetAgentLoading || !currentSessionUserIsResolved) {
    return <LoadingPage />
  }

  if (currentSessionUser.userType !== UserType.admin && currentSessionUser.userType !== UserType.owner) {
    toasts.warning(`You don't have permission to see this page`)
    return <Navigate to={'/profiles/agent-users'} />
  }

  if (!agentData) {
    toasts.warning('Agent not found')
    return <Navigate to={'/profiles/agent-users'} />
  }

  return (
    <NavBars selectedMenuItem="All Users">
      <Flex p={{ xs: 4, sm: 12 }} direction="column">
        <PageHeading
          title="Agent user details"
          imageSrc="/user-management-icon.svg"
          imageAlt="edit user icon"
        />
        <AgentForm
          agentData={agentData}
          isLoading={isGetAgentLoading}
          isReadOnly={true}
        />
      </Flex>
    </NavBars>
  )
}
