import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Notice,
  Select,
  Switch,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { AgentData, App, CreateAgentProps, UserType } from '../../clients'
import { validateEmailFormat } from '../../utils'
import { useGetAllAgencies } from 'src/hooks/agencies/useGetAllAgencies'
import { AgentResource } from '@backend/platform-api/resources/agents'

export type AgentFormProps = {
  agentData?: AgentData
  onSubmit?: (profileData: CreateAgentProps) => void
  isLoading: boolean
  isReadOnly?: boolean
}

export type AmsAppAccessFieldValues = {
  [App.AMS]: boolean
  amsRole?: string
}

export type EnrolmentPlannerAppAccessFieldValues = {
  [App.EnrolmentPlanner]: boolean
  enrolmentPlannerRole?: string
}

export type AddAgentFieldValues = {
  email: string
  givenName: string
  familyName: string
  agencyId: string
} & AmsAppAccessFieldValues

export type PlatformUserTypeOption = {
  label: string
  value: UserType
}

const { Form, Field } = createForm<AddAgentFieldValues>()

export function AgentForm({
  agentData,
  isReadOnly,
  onSubmit,
  isLoading,
}: AgentFormProps): JSX.Element {

  const navigate = useNavigate()
  const { allAgenciesData, isAgenciesLoading } = useGetAllAgencies();

  const initialValues: AddAgentFieldValues = {
    email: agentData?.profile?.email ?? '',
    givenName: agentData?.profile?.givenName ?? '',
    familyName: agentData?.profile?.familyName ?? '',
    agencyId: agentData?.agency?.agencyId ?? '',
    [App.AMS]: true
  }

  return (
    <Form
      onSubmit={(formValue) => {
        onSubmit && onSubmit({
          profile: {
            email: formValue.email,
            givenName: formValue.givenName,
            familyName: formValue.familyName !== '' ? formValue.familyName : undefined,
          },
          agency: {
            agencyId: formValue.agencyId,
          }
        })
      }}
      defaultValues={initialValues}
    >
      <Flex
        py={{ xs: 2, sm: 8 }}
        justify="between"
        align="center"
        gap={10}
        grow={1}
      >
        <Flex direction="column" basis="100%">
          <Box pb={8}>
            <Heading level={2}>Agent information</Heading>
          </Box>
          <Box pb={8}>
            <Field
              as={TextInput}
              label="Email"
              name="email"
              required="Email is required."
              disabled={isReadOnly}
              validate={(email: string) =>
                validateEmailFormat(email) ? true : 'This email is invalid.'
              }
            />
          </Box>
          <Flex pb={8} gap={10}>
            <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
              <Field
                as={TextInput}
                label="Given Name"
                name="givenName"
                required="Given name is required."
                disabled={isReadOnly}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
              <Field
                as={TextInput}
                label="Family Name"
                name="familyName"
                disabled={isReadOnly}
              />
            </Flex.Item>
          </Flex>
          <Flex pb={8} gap={10}>
            <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
              <Field
                as={Select}
                name="agencyId"
                placeholder={isAgenciesLoading ? 'Loading agencies': 'Select'}
                label="Agency name"
                required="Agency name is required"
                disabled={isAgenciesLoading || isReadOnly}
                options={allAgenciesData ? allAgenciesData.data.map((agency) => {
                  return {
                    label: agency.attributes.name,
                    value: agency.id,
                  }
                }) : []}
              />
            </Flex.Item>
          </Flex>
          <Box pb={6}>
            <Divider />
          </Box>
          <RolesAndAccessHeading />
          <RolesAndAccessInfoNotice />
          <Flex
            direction="column"
            justify="start"
            align="start"
            gap={4}
            pt={{ xs: 8, sm: 10 }}
          >
            <Heading level={2}>App Access</Heading>
            <FillBox>
              <AppAccessSwitch
                appName={App.AMS}
                appLabel="Application Management"
                logoSrc="/ams-logo.png"
                isDisabled={true}
              />
            </FillBox>
          </Flex>
          {!isReadOnly && (
            <>
            <Box py={{ xs: 8, sm: 10 }}>
              <Divider />
            </Box>
            <Flex justify="end" gap={4}>
              <Button
                type="button"
                onClick={() => {
                  navigate('/profiles/agent-users')
                }}
                intent="primary"
                emphasis="outlined"
              >
                Cancel
              </Button>
              <Button loading={isLoading} type="submit" intent="primary">
                Add user
              </Button>
            </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Form>
  )
}

function RolesAndAccessInfoNotice(): JSX.Element {
  return (
    <Box pt={6} pb={12}>
      <Notice width="fill">
        <Box>
          <Text variant={'bodyS'}>
            <b>Agent Owner</b> has the highest level of access within the applications authorized for their agency
          </Text>
        </Box>
      </Notice>
    </Box>
  )
}

function RolesAndAccessHeading(): JSX.Element {
  return (
    <Box>
      <Heading level={2}>User Type & Access</Heading>
    </Box>
  )
}

/** Required to manipulate Switch label spacing */
const FillBox = styled.div({
  width: '100%',
  border: '1px solid #EAEEF7',
  padding: '8px 16px',
})

interface AppAccessSwitchProps {
  appName: string
  appLabel: string
  logoSrc: string
  isDisabled: boolean
}

const AppAccessSwitch = ({
  appName,
  appLabel,
  logoSrc,
  isDisabled,
}: AppAccessSwitchProps) => {
  return (
    <Flex
      justify="start"
      align="center"
      grow={1}
      gap={4}
      basis="100%"
      // pt={{ xs: 4, sm: 6 }}
    >
      <Box>
        <img alt={`${appLabel} Logo`} src={logoSrc} width={56} height={56} />
      </Box>
      <Flex.Item basis="100%">
        <Field
          // @ts-ignore - some weird issue with the `as` prop
          as={Switch}
          value={true}
          label={appLabel}
          name={appName as keyof AddAgentFieldValues}
          labelPlacement="left"
          width="fill"
          disabled={isDisabled}
        />
      </Flex.Item>
    </Flex>
  )
}
