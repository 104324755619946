import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Notice,
  Select,
  Switch,
  Text,
  TextInput,
  createForm,
  useToasts,
} from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { App, CreateProfileProps, ProfileData, UserType } from '../../clients'
import { validateEmailFormat } from '../../utils'
import { userTypeToPlatformUserTypeOptions } from './userTypeToPlatformUserTypeOptions'
import { useDeleteProfile } from 'src/hooks'
import { useState } from 'react'
import { ConfirmDeleteDialog } from '../UserManagementTable/ConfirmDeleteDialog'

export type ProfileFormProps = {
  currentSessionUser: ProfileData
  profileDataToBeEdited?: ProfileData
  onSubmit: (profileData: CreateProfileProps) => void
  isLoading: boolean
}

export type AmsAppAccessFieldValues = {
  [App.AMS]: boolean
  amsRole?: string
}

export type EnrolmentPlannerAppAccessFieldValues = {
  [App.EnrolmentPlanner]: boolean
  enrolmentPlannerRole?: string
}

export type AddProfileFieldValues = {
  email: string
  givenName: string
  familyName: string
  userType: string
} & AmsAppAccessFieldValues &
  EnrolmentPlannerAppAccessFieldValues

export type PlatformUserTypeOption = {
  label: string
  value: UserType
}

const { Form, Field } = createForm<AddProfileFieldValues>()

export function ProfileForm({
  currentSessionUser,
  profileDataToBeEdited,
  onSubmit,
  isLoading,
}: ProfileFormProps): JSX.Element {
  const navigate = useNavigate()
  const toasts = useToasts()
  const [profileToDelete, setProfileToDelete] = useState<
    ProfileData | undefined
  >(undefined)
  const { deleteProfile, isDeletingProfile } = useDeleteProfile()

  const initialValues: AddProfileFieldValues = {
    email: profileDataToBeEdited?.email || '',
    givenName: profileDataToBeEdited?.givenName || '',
    familyName: profileDataToBeEdited?.familyName || '',
    userType: profileDataToBeEdited?.userType || '',
    [App.AMS]: profileDataToBeEdited?.apps.includes(App.AMS) || false,
    [App.EnrolmentPlanner]:
      profileDataToBeEdited?.apps.includes(App.EnrolmentPlanner) || false,
  }

  const isEditingOwnProfile =
    currentSessionUser.id === profileDataToBeEdited?.id
  const canEditUserTypeAndApps = currentSessionUser.userType !== UserType.staff
  const canEditProfile =
    currentSessionUser.userType === UserType.admin ||
    currentSessionUser.userType === UserType.owner ||
    isEditingOwnProfile

  return (
    <>
      <Form
        onSubmit={(formValue) => {
          // Create an array to hold the apps that are enabled
          const appsEnabled = []
          if (formValue[App.AMS]) {
            appsEnabled.push(App.AMS)
          }
          if (formValue[App.EnrolmentPlanner]) {
            appsEnabled.push(App.EnrolmentPlanner)
          }

          onSubmit({
            email: formValue.email,
            givenName: formValue.givenName,
            familyName: formValue.familyName,
            userType: formValue.userType as UserType,
            apps: appsEnabled,
          })
        }}
        defaultValues={initialValues}
      >
        <Flex
          py={{ xs: 2, sm: 8 }}
          justify="between"
          align="center"
          gap={10}
          grow={1}
        >
          <Flex direction="column" basis="80%">
            <Box pb={8}>
              <Field
                aria-label="email"
                as={TextInput}
                disabled={profileDataToBeEdited !== undefined}
                label="Email"
                name="email"
                required="Email is required."
                validate={(email: string) =>
                  validateEmailFormat(email) ? true : 'This email is invalid.'
                }
              />
            </Box>
            <Flex pb={8} gap={10}>
              <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
                <Field
                  as={TextInput}
                  disabled={!canEditProfile}
                  aria-label="givenName"
                  label="Given Name"
                  name="givenName"
                  required="Given name is required."
                />
              </Flex.Item>
              <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
                <Field
                  as={TextInput}
                  disabled={!canEditProfile}
                  aria-label="familyName"
                  label="Family Name"
                  name="familyName"
                  required="Family name is required."
                />
              </Flex.Item>
            </Flex>
            {canEditUserTypeAndApps && (
              <>
                <RolesAndAccessHeading />
                <RolesAndAccessInfoNotice />
                <Flex grow={1}>
                  <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
                    <Field
                      as={Select}
                      name="userType"
                      placeholder="Select"
                      label="Platform User Type"
                      required="Enter a platform user type."
                      disabled={isEditingOwnProfile}
                      options={userTypeToPlatformUserTypeOptions(
                        currentSessionUser.userType
                      )}
                    />
                  </Flex.Item>
                </Flex>
                <Flex
                  direction="column"
                  justify="start"
                  align="start"
                  gap={4}
                  pt={{ xs: 8, sm: 10 }}
                >
                  <Heading level={2}>App Access</Heading>
                  <FillBox>
                    <AppAccessSwitch //TODO: This should be dynamic based off subscriptions
                      appName={App.AMS}
                      appLabel="Application Management"
                      logoSrc="/ams-logo.png"
                      isDisabled={isEditingOwnProfile}
                    />
                    <AppAccessSwitch
                      appName={App.EnrolmentPlanner}
                      appLabel="Enrolment Planner"
                      logoSrc="/enrolment-planner.svg"
                      isDisabled={isEditingOwnProfile}
                    />
                  </FillBox>
                </Flex>
              </>
            )}
            <Box py={{ xs: 8, sm: 10 }}>
              <Divider />
            </Box>
            <Flex justify="end" gap={4}>
              {(currentSessionUser.userType === UserType.admin ||
                currentSessionUser.userType === UserType.owner) &&
                profileDataToBeEdited && (
                  <Button
                    type="button"
                    onClick={() => {
                      setProfileToDelete(profileDataToBeEdited)
                    }}
                    intent="negative"
                    emphasis="transparent"
                    disabled={
                      currentSessionUser.id === profileDataToBeEdited?.id
                    }
                  >
                    Remove
                  </Button>
                )}
              <Button
                type="button"
                onClick={() => {
                  navigate('/profiles/school-users')
                }}
                intent="primary"
                emphasis="outlined"
              >
                Cancel
              </Button>
              <Button
                loading={isLoading}
                disabled={!canEditProfile}
                type="submit"
                intent="primary"
              >
                {`${profileDataToBeEdited ? 'Edit' : 'Add'} User`}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>
      {profileToDelete !== undefined && (
        <ConfirmDeleteDialog
          data={profileToDelete}
          isLoading={isDeletingProfile}
          onClose={() => setProfileToDelete(undefined)}
          onConfirm={(id: string) => {
            deleteProfile(
              { id },
              {
                onSuccess: () => {
                  setProfileToDelete(undefined)
                  navigate('/profiles/school-users')
                },
                onError: () => {
                  setProfileToDelete(undefined)
                  toasts.negative(new Error('Error: Unable to remove user.'))
                },
              }
            )
          }}
        />
      )}
    </>
  )
}

function RolesAndAccessInfoNotice(): JSX.Element {
  return (
    <Box pt={6} pb={12}>
      <Notice width="fill">
        <Box pb={2}>
          <Text>
            <b>Admin</b> users can add and remove users, adjust account-wide
            settings, and add additional apps to their account.
          </Text>
        </Box>
        <Box>
          <Text>
            <b>Staff</b> users can only view and update their personal profile
            information and security settings.
          </Text>
        </Box>
      </Notice>
    </Box>
  )
}

function RolesAndAccessHeading(): JSX.Element {
  return (
    <Box>
      <Heading level={2}>User Type & Access</Heading>
    </Box>
  )
}

/** Required to manipulate Switch label spacing */
const FillBox = styled.div({
  width: '50%',
})

interface AppAccessSwitchProps {
  appName: string
  appLabel: string
  logoSrc: string
  isDisabled: boolean
}

const AppAccessSwitch = ({
  appName,
  appLabel,
  logoSrc,
  isDisabled,
}: AppAccessSwitchProps) => {
  return (
    <Flex
      justify="start"
      align="center"
      grow={1}
      gap={8}
      basis="100%"
      pt={{ xs: 4, sm: 6 }}
    >
      <Box>
        <img alt={`${appLabel} Logo`} src={logoSrc} width={56} height={56} />
      </Box>
      <Flex.Item basis="100%">
        <Field
          // @ts-ignore - some weird issue with the `as` prop
          as={Switch}
          label={appLabel}
          name={appName as keyof AddProfileFieldValues}
          labelPlacement="left"
          width="fill"
          disabled={isDisabled}
        />
      </Flex.Item>
    </Flex>
  )
}
