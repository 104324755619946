import { Avatar, MenuButton, Text } from '@applyboard/crystal-ui'
import { LogoutOutlineIcon, PersonOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { useState } from 'react'
import { ProfileData } from '../../clients'
import { elevations } from '../../design/elevation'
import { useLogout } from '../../hooks/navigate'
import { capitalizeTheFirstChar } from '../../utils'

export type HeaderMenuProps = {
  profileData: ProfileData
}

export function HeaderMenu({ profileData }: HeaderMenuProps) {
  const [openMenu, setOpenMenu] = useState(false)
  const { handleLogout } = useLogout()

  const { givenName, familyName, email } = profileData
  const initials = getInitials(givenName, familyName)

  const profileHref = `/profiles/school-users/${profileData.id}`

  // TODO: fetch from profile data once available
  const avatarImgUrl = undefined

  return (
    <MenuButton alignment="right" onOpenChange={setOpenMenu} open={openMenu}>
      <MenuButton.Trigger>
        <AvatarButton aria-label="avatar">
          {avatarImgUrl ? (
            <Avatar alt={initials} size="sm" image={avatarImgUrl} />
          ) : (
            <Avatar alt={initials} size="sm" initials={initials} />
          )}
        </AvatarButton>
      </MenuButton.Trigger>
      <MenuButton.Header
        onClickClose={() => {
          setOpenMenu(false)
        }}
      >
        <HeaderWrapper>
          <Text variant="titleS" overflow="ellipsis">
            {capitalizeTheFirstChar(givenName)}{' '}
            {capitalizeTheFirstChar(familyName)}
          </Text>
          <Text variant="bodyM" overflow="ellipsis">
            {email}
          </Text>
        </HeaderWrapper>
      </MenuButton.Header>
      <MenuButton.Link
        href={profileHref}
        id={crypto.randomUUID()}
        icon={PersonOutlineIcon}
      >
        Edit User
      </MenuButton.Link>
      <MenuButton.Link
        href="/"
        id={crypto.randomUUID()}
        onClick={handleLogout}
        icon={LogoutOutlineIcon}
      >
        Logout
      </MenuButton.Link>
    </MenuButton>
  )
}

const AvatarButton = styled.button(({ theme }) => ({
  backgroundColor: theme.colors.actionPrimary,
  border: '1px solid transparent',
  borderRadius: '9999px',
  '&:hover': {
    boxShadow: elevations.z1,
    cursor: 'pointer',
  },
}))

const HeaderWrapper = styled.div({
  maxWidth: '250px',
})

function getInitials(givenName: string, familyName: string): string {
  return `${givenName[0].toUpperCase()}${familyName[0].toUpperCase()}`
}
